.logo {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
}

.logo h3 {
    font-weight: 600;
    font-size: 20px;
    color: #FFF;
    margin: 0;
}